import _ from 'lodash';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
// import { parse } from 'node-html-parser';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import {
  DATALAYER_TYPE,
  sendDataLayerGlobal
} from 'src/utils/datalayer/send-datalayer';
// import { useFetch } from 'services/useFetch';
import { FaEye, FaRegCalendarAlt, FaUser } from 'react-icons/fa';
import CardV from 'src/components/cards/card-v';
import EmptyArticle from 'src/components/empty';
import Layout from 'src/components/layouts';
import WidgetSocialShare from 'src/components/widgets/widget-social-share';
import {
  APP_IMG,
  APP_URL,
  IS_NO_ROBOT_INDEX,
  // IS_SHOW_COOKIE,
  LAST_ARTICLE_ID,
  NEXT_DEFAULT_COUNT_VIEW,
  SHOW_COMMEMORATE
} from 'src/constants';
import {
  IMAGE_DEFAULT,
  LOGO_192,
  WEB_DESC,
  WEB_KEYWORD,
  WEB_NAME,
  WEB_URL
} from 'src/constants/layout';
// import WidgetCookie from 'src/components/widgets/widget-cookie';

import {
  arrayEmpty,
  convertObjPath,
  escapeChar,
  rewriteTxt,
  timestamp,
  useDateUpdate,
  useDesktop,
  usePageViews
} from 'src/utils/helper';

import { KEY_ADS_POSITION } from 'src/components/ads/dynamic-ads';
import { KEY_HEADING_TAG } from 'src/components/heading/heading-tag';
import WidgetFollowUs from 'src/components/widgets/widget-follow-us';
import {
  getAffiliate,
  getFeatureImage
  // useImageCaption
  // setImages,
} from 'src/utils/contents';
import { getCountviews } from 'src/utils/countview';
// import { getSizeIframe } from 'src/utils/contents/size-iframe';
import { useMediaQuery } from 'react-responsive';
import Carousels from 'src/components/carousels';
import WidgetGallery from 'src/components/gallery/widget-gallery';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { setContents } from 'src/utils/contents/set-contents';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import { GET, GET_ARTICLES } from 'src/utils/services';
import useUser from 'src/utils/useUser';
import styled from 'styled-components';
// import WidgetStickyContents from 'src/components/widgets/translates/widget-sticky-contents';

const HeadingTag = dynamic(import('src/components/heading/heading-tag'), {
  ssr: false
});
const StickyBox = dynamic(import('react-sticky-box'), { ssr: false });
const DynamicAds = dynamic(import('src/components/ads/dynamic-ads'), {
  ssr: true
});
const SectionAffiliate = dynamic(
  import('src/components/sections/section-event/section-affiliate')
);
const AdsNative = dynamic(import('src/components/ads/ads-native'), {
  ssr: true
});

const PageDetail = ({
  article,
  related,
  lastNews,
  solrRelated,
  dataAdsNative,
  dataAffiliate,
  dataAdsHeader,
  ads,
  agent
}) => {
  const _data = useUser();
  const sessionSevId = _data?.user ? decryptionClient(_data?.user) : null;
  if (!article) return <EmptyArticle sessionSevId={sessionSevId} />;

  // const [isTranslate, setIsTranslate] = useState(false);
  // const [isKeyPoint, setIsKeyPoint] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  // const [selectLang, setSelectLang] = useState('TH');
  // const [newsArticle, setNewsArticle] = useState({ ...article });
  // useEffect(() => {
  //   const keypointContent = document.getElementById('keypoint-content');
  //   while (keypointContent?.firstChild) {
  //     keypointContent?.removeChild(keypointContent?.firstChild);
  //   }

  //   if (isKeyPoint === true && !_.isEmpty(_article?.key_point)) {
  //     const element = document.getElementById('scrollto');
  //     element?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  //     const div = document.createElement('div');
  //     div.innerHTML = _article?.key_point || '';

  //     const strongElements = div.getElementsByTagName('strong');
  //     for (let i = 0; i < strongElements.length; i++) {
  //       strongElements[i].style.fontWeight = 'bold';
  //     }

  //     const speed = NEXT_PUBLIC_SPEED_KEYPOINT ?? 50;

  //     let txt = _article?.key_point;
  //     let i = 0;

  //     txt = htmlToPlainText(txt?.replace(/<\/?[^>]+(>|$)/g, (match) => (match === '<li>' ? '_' : '')));

  //     const ul = document.createElement('ul');
  //     let li = document.createElement('div');

  //     const intervalId = setInterval(() => {
  //       if (i < txt.length) {
  //         const char = txt.charAt(i);
  //         if (char === '_') {
  //           keypointContent?.appendChild(ul);
  //           li = document.createElement('li');
  //           // Create a new li element for the next line
  //           keypointContent?.appendChild(li);
  //           ul.appendChild(li);
  //           i++;
  //         } else {
  //           li.innerHTML += char;
  //           i++;
  //         }
  //       }
  //       // else {
  //       //     clearInterval(intervalId);
  //       // }
  //     }, speed);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [isKeyPoint]);
  // const handleTranslate = async (newLanguage) => {
  //   try {
  //     setSelectLang(newLanguage);
  //     setIsLoading(true);
  //     setIsTranslate(!isTranslate);
  //     setIsKeyPoint(false);
  //     if (NEXT_PUBLIC_URL_SOUND !== undefined || !_.isEmpty(article?.id)) {
  //       const uriToken = `${NEXT_PUBLIC_URL_SOUND}/create`;
  //       const getToken = await axios
  //         .post(
  //           uriToken,
  //           {
  //             app: 'default'
  //           },
  //           {
  //             headers: {
  //               'Content-Type': 'application/json',
  //               'User-Agents': `NationSpeech/1.0 ${agent}`
  //             }
  //           }
  //         )
  //         .catch(error => {
  //           console.error(error);
  //         });

  //       // console.log({ getToken });
  //       if (getToken?.data?.data?.token !== undefined) {
  //         const uriEn = `${NEXT_PUBLIC_URL_SOUND}/translates/${article?.id}/${newLanguage}`;
  //         // console.log('uriEn', uriEn);

  //         const translate = await axios
  //           .post(
  //             uriEn,
  //             {
  //               data: {
  //                 id: article?.id,
  //                 site: NEXT_PUBLIC_WEB_NAME_SOUND,
  //                 language: newLanguage
  //               }
  //             },
  //             {
  //               headers: {
  //                 'Content-Type': 'application/json',
  //                 'User-Agents': `NationSpeech/1.0 ${agent}`,
  //                 accessToken: getToken?.data?.data?.token,
  //                 accessTimeStamp: getToken?.data?.data?.timestamp
  //               }
  //             }
  //           )
  //           .catch(error => {
  //             console.error(error);
  //           });
  //         await new Promise(resolve => setTimeout(resolve, 500));
  //         setNewsArticle({
  //           ...article,
  //           ...translate?.data?.data
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     setIsLoading(true);
  //     console.log(error, 'Error Translate ');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const _article = {
    ...article,
    // ...newsArticle,
    description: article?.meta?.description || WEB_DESC,
    keyword: article?.meta?.keyword || WEB_KEYWORD,
    image_url: article?.meta?.og_social_image || IMAGE_DEFAULT
  };
  const router = useRouter();
  const [updateCountView, setUpdateCountView] = useState(0);
  const dateUpdate = useDateUpdate(_article?.published_at);
  // const countViewUpdate = updateCountView;
  const baseUrlCategory = `${APP_URL}${_article?.link}`;

  /* ----------------------------------- SEO ---------------------------------- */
  const articleTitleSeo = escapeChar(_article?.title);
  const articleDescriptionSeo = escapeChar(_article?.description);
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    /* ------------------------------ GET COUNTVIEW ----------------------------- */
    (async () => {
      try {
        const _countviews = await getCountviews(_article?.id);
        if (_countviews) {
          setUpdateCountView(_countviews + Number(_article?.pageviews));
        }
      } catch (error) {
        setUpdateCountView(0);
      }
    })();
    /* ---------------------------- SET IMAGE_CAPTION --------------------------- */
    // useImageCaption('contents');
    // -------------------------------- SET IFRAME --------------------------------
    // getSizeIframe('contents');
    // -------------------------------- SET IMAGES --------------------------------
    // setImages('contents');
    setContents();
  }, []);

  // ACCEPT COOKIE ********************************
  const handleScrollAdsPostArticle = () => {
    const sectionEl = document.getElementById('cookie');
    const sticky = document.querySelector('.sticky-cookie');
    // const cookies = document.querySelector('.displayC');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop + 300) {
        if (sticky) {
          sticky.style.display = 'block';
        }
        // if (IS_SHOW_COOKIE === 'true') {
        //   cookies.style.display = 'none';
        // }
      } else {
        if (sticky) {
          sticky.style.display = 'none';
        }
        // if (IS_SHOW_COOKIE === 'true') {
        //   cookies.style.display = 'unset';
        // }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScrollAdsPostArticle);
    return () =>
      document.removeEventListener('scroll', handleScrollAdsPostArticle);
  });

  /* -------------------------------- DATALAYER ------------------------------- */

  const nextSectionIndexRef = useRef(0);
  const sectionsVisibleRef = useRef([]);
  const sectionRef = useRef(null);
  const footerRef = useRef(false);
  const loadMoreArticles = () => {
    try {
      if (nextSectionIndexRef?.current < related.length) {
        if (isShouldFetch()) {
          const currentIndex = nextSectionIndexRef.current;
          const section = related[currentIndex];

          nextSectionIndexRef.current = currentIndex + 1;
          sectionsVisibleRef.current = [...sectionsVisibleRef.current, section];

          setTimeout(() => {
            loadMoreArticles();
          }, 1000);
        } else {
          document.addEventListener('scroll', handleScroll);
        }
      }
    } catch (err) {
      console.error(
        `${timestamp()}==========> INDEX ERROR : ${
          nextSectionIndexRef.current
        }`,
        err
      );
    }
  };

  const handleParagraphOne = async () => {
    const sectionEl = await document.getElementById('paragraph-1');

    if (sectionEl) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'paragraph-1';

        document.removeEventListener('scroll', handleParagraphOne);
        await sendDataLayerGlobal({
          type: DATALAYER_TYPE.TRACK_SCROLL,
          router: router?.pathname,
          section: 'scroll',
          data: {
            title: router.asPath,
            heading: 'paragraph-1'
          }
        });
      }
    }
  };

  const handleParagraphTwo = async () => {
    const sectionEl = await document.getElementById('paragraph-2');

    if (sectionEl) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'paragraph-2';
        document.removeEventListener('scroll', handleParagraphTwo);
        await sendDataLayerGlobal({
          type: DATALAYER_TYPE.TRACK_SCROLL,
          router: router?.pathname,
          section: 'scroll',
          data: {
            title: router.asPath,
            heading: 'paragraph-2'
          }
        });
      }
    }
  };

  const handleParagraphThree = async () => {
    const sectionEl = await document.getElementById('paragraph-3');
    if (sectionEl) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'paragraph-3';
        document.removeEventListener('scroll', handleParagraphThree);
        await sendDataLayerGlobal({
          type: DATALAYER_TYPE.TRACK_SCROLL,
          router: router?.pathname,
          section: 'scroll',
          data: {
            title: router.asPath,
            heading: 'paragraph-3'
          }
        });
      }
    }
  };
  const handleScrollToReadComplete = async () => {
    try {
      const sectionEl = await document.getElementById('section-ads');
      if (sectionEl !== null) {
        if (sectionEl.offsetTop < document.documentElement.scrollTop) {
          // console.log(sectionEl);
          // console.log(localStorage.getItem('_t'), isNaN(localStorage.getItem('_t')));
          sectionRef.current = 'section-ads';
          const _t = localStorage.getItem('_t');
          if (!isNaN(_t)) {
            const counter = parseInt(_t);
            const toArray = (num) =>
              num && [...`${num}`].map((elem) => parseInt(elem));
            const _a =
              toArray(counter)[toArray(counter).length - 1] === 0 ? 0 : 1;
            const _b = counter - 1;
            const start = Math.floor((_a === 0 ? _b : counter) / 10) * 10;
            const end = Math.ceil(counter / 10) * 10;
            const first = start === 0 ? 1 : start + 1;
            const second = end === 0 ? 10 : end;
            const timer = `${first}-${second}`;

            document.removeEventListener('scroll', handleScrollToReadComplete);
            await sendDataLayerGlobal({
              type: DATALAYER_TYPE.TRACK_READTIME,
              router: router?.pathname,
              section: 'scroll',
              data: {
                title: router.asPath,
                heading: 'read-complete',
                time: timer
              }
            });
            await localStorage.removeItem('_t');
          } else {
            document.removeEventListener('scroll', handleScrollToReadComplete);
            await sendDataLayerGlobal({
              type: DATALAYER_TYPE.TRACK_READTIME,
              router: router?.pathname,
              section: 'scroll',
              data: {
                title: router.asPath,
                heading: 'read-complete',
                time: '1-10'
              }
            });
            await localStorage.removeItem('_t');
          }
        }
      }
    } catch (err) {
      console.error(
        `${timestamp()} ==========> handleScrollToReadComplete ERROR`
      );
    } finally {
      footerRef.current = true;
    }
  };
  const handleScrollToSectionFooter = async () => {
    const sectionEl = await document.getElementById('footer-wrapper');
    if (footerRef.current) {
      if (sectionEl?.offsetTop < document.documentElement.scrollTop + 500) {
        document.removeEventListener('scroll', handleScrollToSectionFooter);
        await sendDataLayerGlobal({
          type: DATALAYER_TYPE.TRACK_SCROLL,
          router: router?.pathname,
          section: 'scroll',
          data: {
            title: router.asPath,
            heading: 'footer-wrapper'
          }
        });
      }
    }
  };
  useEffect(() => {
    try {
      localStorage.setItem('_t', '0');
      const increment = () => {
        const count = localStorage.getItem('_t');
        const time = parseInt(count);
        localStorage.setItem('_t', time + 1);
      };
      setInterval(increment, 1000);
    } catch (err) {
      // PROVIDE FEEDBACK TO THE USER
      console.log('Error: Please enable website cookies. ' + err);
    }
  }, []);

  const handleScroll = () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      loadMoreArticles();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleParagraphOne);
    return () => document.removeEventListener('scroll', handleParagraphOne);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleParagraphTwo);
    return () => document.removeEventListener('scroll', handleParagraphTwo);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleParagraphThree);
    return () => document.removeEventListener('scroll', handleParagraphThree);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToReadComplete);
    return () =>
      document.removeEventListener('scroll', handleScrollToReadComplete);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToSectionFooter);
    return () =>
      document.removeEventListener('scroll', handleScrollToSectionFooter);
  }, []);
  // handleCommemorate
  function getScrollTop () {
    if (typeof window.pageYOffset !== 'undefined') {
      // Most browsers
      return window.pageYOffset;
    }

    const d = document.documentElement;
    if (typeof d.clientHeight !== 'undefined') {
      // IE in standards mode
      return d.scrollTop;
    }

    // IE in quirks mode
    return document.body.scrollTop;
  }
  const isMb = useMediaQuery({ query: '(max-width: 768px)' });
  useEffect(() => {
    if (isMb) {
      window.onscroll = function () {
        const box = document.getElementById('widget-group-js');
        if (!_.isEmpty(box)) {
          const scroll = getScrollTop();
          if (scroll <= 0) {
            box.style.top = '150px';
          } else {
            box.style.top = 150 + scroll + 2 + 'px';
          }
        }
      };
    }
  }, []);
  // handleCommemorate
  // ***********************************************************************
  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        noindex={IS_NO_ROBOT_INDEX}
        nofollow={IS_NO_ROBOT_INDEX}
        title={_article?.title}
        description={_article?.description}
        openGraph={{
          type: 'article',
          locale: 'th_TH',
          site_name: WEB_NAME,
          title: _article?.title,
          description: _article?.description,
          url: baseUrlCategory,
          article: {
            publishedTime: _article?.published_at,
            modifiedTime: _article?.updated_at,
            expirationTime: null,
            section: _article?.section?.th,
            authors: [`${WEB_URL.FACEBOOK}`],
            tags: _article?.tags
          },
          images: [
            {
              url: _article?.image_url,
              width: '700',
              height: '395',
              alt: _article?.title
            }
          ]
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article?.keyword
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={`${_article?.canonical}`} />
        <meta property='twitter:title' content={_article?.title} />
        <meta property='twitter:description' content={_article?.description} />
        <meta
          property='twitter:image'
          content={_article?.image_url || IMAGE_DEFAULT}
        />

        {/* ------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="${_article?.section?.en}";
              `
          }}
        />

        {/* ---------------------------- SNIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "name": "${WEB_NAME}",
                  "url": "${WEB_URL.WEB}",
                  "description": "${articleDescriptionSeo}",
                  "sameAs": [
                    "${WEB_URL.FACEBOOK}",
                    "${WEB_URL.TWITTER}",
                    "${WEB_URL.YOUTUBE}",
                    "${WEB_URL.LINE}",
                    "${WEB_URL.INSTAGRAM}"
                  ],
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${WEB_URL.WEB}search?q={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                }
              `
          }}
        />

        {/* ---------------------------- SNIPPET : WebPage --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "WebPage", 
                  "name": "${articleTitleSeo}", 
                  "description": "${articleDescriptionSeo}", 
                  "publisher": { 
                    "@type": "ProfilePage", 
                    "name": "${WEB_NAME}" 
                  } 
                } 
              `
          }}
        />

        {/* ------------------------ SNIPPET : BreadcrumbList ------------------------ */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [ 
                    { 
                      "@type": "ListItem", 
                      "position": 1, 
                      "name": "หน้าแรก", 
                      "item": "${APP_URL}" 
                    }, 
                    { 
                      "@type": "ListItem", 
                      "position": 2, 
                      "name": "${_article?.section?.th}", 
                      "item": "${APP_URL}/${_article?.section?.en}" 
                    }, 
                    { 
                      "@type": "ListItem", 
                      "position": 3, 
                      "name": "${articleTitleSeo}", 
                      "item": "${
                        _article?.canonical || `${APP_URL}${_article?.link}`
                      }"
                    } 
                  ] 
                } 
              `
          }}
        />

        {/* -------------------------- SNIPPET : NewsArticle ------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "NewsArticle", 
                  "mainEntityOfPage": { 
                    "@type": "WebPage", 
                    "@id": "${
                      _article?.canonical || `${APP_URL}${_article?.link}`
                    }"
                  }, 
                  "headline": "${articleTitleSeo}", 
                  "alternativeHeadline": "${articleTitleSeo}", 
                  "description": "${articleDescriptionSeo}", 
                  "image": "${APP_IMG}${_article?.image}", 
                  "datePublished": "${_article?.published_at}",
                  "dateModified": "${_article?.updated_at}",
                  "author": { 
                    "@type": "Thing", 
                    "name": "${WEB_NAME}",
                    "url": "${APP_URL}"
                  }, 
                  "publisher": { 
                    "@type": "Organization", 
                    "name": "${WEB_NAME}", 
                    "logo": { 
                      "@type": "ImageObject", 
                      "url": "${APP_IMG}/static/${LOGO_192}" 
                    } 
                  } 
                } 
              `
          }}
        />

        {/* --------------------------- Social embed script -------------------------- */}
        <script async src='//www.instagram.com/embed.js' />
        <script async src='https://www.tiktok.com/embed.js' />
        {/* <script async src='https://platform.twitter.com/widgets.js' /> */}

        {/* {article.is_show_ads && parse(dataAdsNative?.header)} */}
        {/* { _article.is_show_ads && <script type='text/javascript' src='https://jsc.mgid.com/t/h/thainewsonline.co.1115175.js' async /> } */}

        {/* MGID */}
        {/* <script src='https://jsc.mgid.com/t/n/tnews.co.th.986049.js' async></script> */}
        {/* -------------------------------- ADS : PPN ------------------------------- */}
        {!useDesktop() && !_.isEmpty(dataAdsHeader)
          ? parse(`${dataAdsHeader?.header_mobile}`)
          : ''}
        {useDesktop() && !_.isEmpty(dataAdsHeader)
          ? parse(`${dataAdsHeader?.header_desktop}`)
          : ''}

        {/* <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
        <script type='text/javascript' dangerouslySetInnerHTML={{
          __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
        }} /> */}
      </Head>
      {!_.isEmpty(dataAffiliate) && (
        <SectionAffiliate position={4} data={dataAffiliate} />
      )}

      <Layout ads={ads} sessionSevId={sessionSevId}>
        {/* -------------------------- ADS-PPN : Billboard ------------------------------ */}
        {/* <div className='overflow-hidden default-billboard'> */}
        {/* </div> */}
        {/* ------------------------------ MAIN : START ------------------------------ */}
        <div
          className='detail-wrapper'
          style={{
            filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
          }}
          >
          {_article.is_show_ads && (
            <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />
          )}
          <div className='container readpage'>
            {/* ----------------------------------- CONTENTS : Start ---------------------------------- */}
            <div className='article-area'>
              <div className='row'>
                <div className='col-12 col-lg-8'>
                  {/* ------------------------------ HEADING : START ----------------------------- */}
                  <div className='breadcrumb-area'>
                    <div className='breadcrumb-title'>
                      <div className='badge'>
                        <a
                          href={
                            _article.subCategory?.th
                              ? `/category/${_article?.section?.en}/${_article?.subCategory?.en}`
                              : `/category/${_article?.section?.en}`
                          }
                        >
                          <span>
                            {' '}
                            {_article.subCategory?.th
                              ? _article?.subCategory?.th
                              : _article?.section?.th}
                          </span>
                        </a>
                      </div>
                      <h1 className='category'>{_article?.title}</h1>
                      <ul>
                        <li>
                          <a href='/'>หน้าแรก</a>
                        </li>
                        {_article.section?.th && (
                          <li>
                            <a href={`/category/${_article?.section?.en}`}>
                              {_article?.section?.th}
                            </a>
                          </li>
                        )}
                        {_article?.subCategory?.th && (
                          <li>
                            <a
                              href={`/category/${_article?.section?.en}/${_article?.subCategory?.en}`}
                            >
                              {_article?.subCategory?.th}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {/* ------------------------------ HEADING : END ----------------------------- */}
                  <ImageWrapper className='content-feature-image '>
                    {getFeatureImage(_article)}
                  </ImageWrapper>
                  {/* <WidgetStickyContents className='block-sticky' isLoading={isLoading} article={article} handleTranslate={handleTranslate} selectLang={selectLang} router={router} /> */}
                  <div className='byline'>
                    {article?.byline && (
                      <span>
                        <FaUser /> By {article?.byline}
                      </span>
                    )}
                  </div>
                  <div id='contents' className='article-content'>
                    <div className='post-info'>
                      <ul className='dateUpdate'>
                        <li>
                          <FaRegCalendarAlt />
                          {dateUpdate}
                        </li>
                        {updateCountView > NEXT_DEFAULT_COUNT_VIEW
                          ? (
                          <li>
                            <FaEye />
                            {usePageViews(updateCountView)}
                          </li>
                            )
                          : null}
                      </ul>
                      <div className='justify-content-between align-items-center d-inline-flex'>
                        <span className='mx-2 d-none d-lg-flex'>แชร์ | </span>
                        <WidgetSocialShare
                          articleLink={article?.link}
                          //! DATALAYER
                          eventDataLayer={{
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router?.pathname,
                            section: 'แชร์ข่าว',
                            position: 'ใต้:header',
                            data: {
                              block: '1',
                              heading: 'แชร์ข่าว'
                            }
                          }}
                        />
                      </div>
                    </div>
                    {!_.isEmpty(_article?.key_point)
                      ? <div className='keypoint-wrapper'>
                      <HeadSeo
                        dangerouslySetInnerHTML={{
                          __html: `${_article?.blurb || ''}`
                        }}
                      />
                      <div className='keypoint-header'>
                        <p>KEY</p>
                        <p>POINTS</p>
                      </div>
                      <div
                        className='keypoint-lists'
                        dangerouslySetInnerHTML={{
                          __html: _article.key_point ?? ''
                        }}
                      />
                    </div>

                      : _article?.blurb && (
                      <>
                        <h2 className='content-blurb'>{_article.blurb}</h2>
                      </>
                      )}

                    <div id='paragraph'>
                      {/* ------------------------- CONTENTS I and ADS : INARTICLE 1 ------------------------- */}
                      {_article?.content_I && (
                        <div id='paragraph-1' className='default-inread'>
                          {_article?.is_show_ads && (
                            <DynamicAds
                              position={KEY_ADS_POSITION.INARTICLE_1}
                              data={ads}
                            />
                          )}

                          <div
                            className='content-detail'
                            dangerouslySetInnerHTML={{
                              __html: _article?.content_I
                            }}
                          />
                          {_article?.is_show_ads &&
                            getAffiliate(1, dataAffiliate)}
                        </div>
                      )}

                      {/* ------------------------- CONTENTS II and ADS : INARTICLE 2 ------------------------ */}
                      {_article?.content_II && (
                        <div id='paragraph-2' className='default-inread'>
                          {_article?.is_show_ads && (
                            <DynamicAds
                              position={KEY_ADS_POSITION.INARTICLE_2}
                              data={ads}
                            />
                          )}
                          <div
                            className='content-detail'
                            dangerouslySetInnerHTML={{
                              __html: _article?.content_II
                            }}
                          />
                          {_article?.is_show_ads &&
                            getAffiliate(2, dataAffiliate)}
                        </div>
                      )}

                      {/* -------------------------- CONTENTS III and ADS : INARTICLE 3 ----------------------- */}
                      {_article?.content_III && (
                        <div id='paragraph-3' className='default-inread'>
                          {_article?.is_show_ads && (
                            <DynamicAds
                              position={KEY_ADS_POSITION.INARTICLE_3}
                              data={ads}
                            />
                          )}
                          <div
                            className='content-detail'
                            dangerouslySetInnerHTML={{
                              __html: _article?.content_III
                            }}
                          />
                          {_article?.is_show_ads &&
                            getAffiliate(3, dataAffiliate)}
                        </div>
                      )}
                    </div>
                    <WidgetGallery
                        id={_article?.gallery_src?.split('/').pop() ?? '14'}
                        title={_article?.title}
                        article={_article}
                        data={_article?.gallery}
                        articleLink={_article?.link}
                        // ! DATALAYER
                        link={_article?.gallery_src ?? '/galleries/14'}
                        eventDataLayer={{
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'อัลบั้มรูป',
                          position: 'ใต้:เนื้อหาที่ 3',
                          data: {
                            heading: 'อัลบั้มรูป'
                          }
                        }}
                      />
                    {/* { !_.isEmpty(related) &&
                    <WidgetNews
                      className='section--tag '
                      lastNews={related?.slice(0, 3)}
                      heading='ข่าวที่เกี่ยวข้อง'
                      // ! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router.pathname,
                        section: 'ข่าวที่เกี่ยวข้อง',
                        position: 'เนื้อหาที่-3',
                        data: {
                          block: '1',
                          heading: 'ข่าวที่เกี่ยวข้อง'
                        }
                      }}
                    />} */}

                    {/* ------------------------------ READ_COMPLETE : TAGS - DESKTOP ------------------------ */}
                    <div className='content-tags d-none d-md-block'>
                      <span>Tags</span>
                      {!arrayEmpty(_article?.tags) &&
                        _article.tags.map((item, index) => (
                          <a
                            key={index}
                            href={`/tags/${item}`}
                            //! DATALAYER
                            onClick={() =>
                              sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                section: 'แท็กที่เกี่ยวข้อง',
                                position: 'ใต้:ข่าวที่เกี่ยวข้อง',
                                data: {
                                  block: '1',
                                  title: item,
                                  heading: 'แท็กที่เกี่ยวข้อง',
                                  index: index + 1
                                }
                              })
                            }
                          >
                            {item}
                          </a>
                        ))}

                      <div className='section-sharelink --mobile'>
                        <div className='mt-4'>
                          <WidgetSocialShare
                            articleLink={article?.link}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_POSITION,
                              router: router?.pathname,
                              section: 'แชร์ข่าว',
                              position: 'ใต้:แท็กที่เกี่ยวข้อง',
                              data: {
                                block: '1',
                                heading: 'แชร์ข่าว'
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='section-sharelink --mobile d-flex d-md-none'>
                      <div className='mt-4'>
                        <WidgetSocialShare
                          articleLink={article?.link}
                          //! DATALAYER
                          eventDataLayer={{
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router?.pathname,
                            section: 'แชร์ข่าว',
                            position: 'ใต้:แท็กที่เกี่ยวข้อง',
                            data: {
                              block: '1',
                              heading: 'แชร์ข่าว'
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* ------------------------------ READ_COMPLETE : TAGS - MOBILE ------------------------ */}
                    <div className='content-tags d-block d-md-none'>
                      <span>Tags</span>
                      {!arrayEmpty(_article?.tags) &&
                        _article.tags.map((item, index) => (
                          <a
                            key={index}
                            href={`/tags/${item}`}
                            //! DATALAYER
                            onClick={() =>
                              sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                section: 'แท็กที่เกี่ยวข้อง',
                                position: 'ใต้:ข่าวที่เกี่ยวข้อง',
                                data: {
                                  block: '1',
                                  title: item,
                                  heading: 'แท็กที่เกี่ยวข้อง',
                                  index: index + 1
                                }
                              })
                            }
                          >
                            {item}
                          </a>
                        ))}
                    </div>
                    <div className='col-12 col-lg-12 block-readmore-content mt-4 wrapper-interest-news'>
                      <div className='section-title'>
                        <HeadingTag
                          title={'ข่าวที่เกี่ยวข้อง'}
                          type={KEY_HEADING_TAG.DETAIL}
                        />
                      </div>
                      {!_.isEmpty(related) && (
                        <Carousels slideShow={3} >
                          {related?.map((blockItem, index) => (
                            <CardV
                              key={index}
                              data={blockItem}
                              path={blockItem.link}
                              //! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                section: 'ข่าวที่เกี่ยวข้อง',
                                position: 'ใต้:แท็กที่เกี่ยวข้อง',
                                data: {
                                  title: blockItem?.title,
                                  block: '1',
                                  heading: 'ข่าวที่เกี่ยวข้อง',
                                  carousel: index + 1
                                }
                              }}
                            />
                          ))}
                        </Carousels>
                      )}
                    </div>
                    {/* {!arrayEmpty(solrRelated) && (
                      <div className='d-lg-none d-block mobile-solr-item mt-3'>
                        <Carousels>
                          {solrRelated.slice(0, 5).map((item, index) => (
                            <CardH
                              key={index}
                              data={item}
                              path={item.link}
                              external={true}
                              //! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                section: 'ข่าวที่เกี่ยวข้อง',
                                position: 'ใต้:แท็กที่เกี่ยวข้อง',
                                data: {
                                  title: item?.title,
                                  block: '1',
                                  heading: 'ข่าวที่เกี่ยวข้อง',
                                  carousel: index + 1
                                }
                              }}
                            />
                          ))}
                        </Carousels>
                      </div>
                    )} */}
                    {/* <div className='d-none d-lg-block col-12 col-lg-12 block-readmore-content mt-4 wrapper-interest-news'>
                      {!_.isEmpty(related) && (
                        <Carousels infinite={related.lenght > 3}>
                          {related?.map((blockItem, index) => (
                            <CardV
                              key={index}
                              data={blockItem}
                              path={blockItem.link}
                              //! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                section: 'ข่าวที่เกี่ยวข้อง',
                                position: 'ใต้:แชร์ข่าว',
                                data: {
                                  title: blockItem?.title,
                                  block: '1',
                                  heading: 'ข่าวที่เกี่ยวข้อง',
                                  carousel: index + 1
                                }
                              }}
                            />
                          ))}
                        </Carousels>
                      )}
                    </div> */}
                  </div>
                   {/* ------------------------ ADS : Postartice and Cookie ---------------------- */}
                   <div id='cookie' className='displayUnset'>
                      <div className='ads-postartice'>
                        {/* ----------------------------- ADS : POSTARTICE -------------------------- */}
                        {_article?.is_show_ads && (
                          <DynamicAds
                            position={KEY_ADS_POSITION.POSTARTICLE}
                            data={ads}
                          />
                        )}
                      </div>
                    </div>
                  <div className='col-12 block-readmore-content mt-5'>
                    {/* --------------------------- ADS : Native (Infinities) ----------------------------------- */}
                    {_article.is_show_ads && (
                      <AdsNative
                        data={dataAdsNative}
                        isShowAds={article?.is_show_ads}
                      />
                    )}
                  </div>
                </div>

                {/* ---------------------------- BLOCKSIDE : START --------------------------- */}
                <div className='col-12 col-lg-4 d-none d-lg-inline-block'>
                  <StickyBox offsetTop={80} offsetBottom={50}>
                    {/* -------------------------------- FOLLOW US ------------------------------- */}
                    <div className='mt-3 mb-7'>
                      <WidgetFollowUs />
                    </div>
                    {/* ----------------------------- ADS : STICKY-1 -------------------------- */}
                    <div className='default-sticky'>
                      {_article.is_show_ads && (
                        <DynamicAds
                          position={KEY_ADS_POSITION.STICKY_1}
                          data={ads}
                        />
                      )}
                    </div>
                    <div className='side-article'>
                      {lastNews?.length > 0 && (
                        <div className='mb-3'>
                          <div className='section-title'>
                            <HeadingTag
                              title={'ข่าวล่าสุด'}
                              type={KEY_HEADING_TAG.DETAIL}
                            />
                          </div>
                          <div className='row'>
                            {lastNews.slice(0, 4).map((item, index) => (
                              <div className='col-6' key={index}>
                                <CardV
                                  inContent={true}
                                  data={item}
                                  path={item?.link}
                                  //! DATALAYER
                                  eventDataLayer={{
                                    type: DATALAYER_TYPE.TRACK_POSITION,
                                    router: router?.pathname,
                                    section: 'ข่าวล่าสุด',
                                    position: 'ใต้:ติดตามเรา',
                                    data: {
                                      block: '2',
                                      title: item.title,
                                      heading: 'ข่าวล่าสุด',
                                      index: index + 1
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* ----------------------------- ADS : STICKY-2 -------------------------- */}
                      {_article.is_show_ads && (
                        <DynamicAds
                          position={KEY_ADS_POSITION.STICKY_2}
                          data={ads}
                        />
                      )}

                      {/* {!arrayEmpty(solrRelated) && (
                        <div className='mb-3'>
                          <div className='section-title'>
                            <HeadingTag
                              title={'ข่าวที่น่าสนใจ'}
                              type={KEY_HEADING_TAG.DETAIL}
                            />
                          </div>
                          <div className='row'>
                            {solrRelated.slice(0, 5).map((item, index) => (
                              <div key={index} className='col-12'>
                                <CardH
                                  external={true}
                                  data={item}
                                  path={item.link}
                                  //! DATALAYER
                                  eventDataLayer={{
                                    type: DATALAYER_TYPE.TRACK_POSITION,
                                    router: router?.pathname,
                                    section: 'ข่าวที่น่าสนใจ',
                                    position: 'ใต้:ข่าวล่าสุด',
                                    data: {
                                      block: '2',
                                      title: item.title,
                                      heading: 'ข่าวที่น่าสนใจ',
                                      index: index + 1
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )} */}
                    </div>
                  </StickyBox>
                  {/* ---------------------------- BLOCKSIDE : END --------------------------- */}
                </div>
              </div>
            </div>
            {/* ----------------------------------- CONTENTS : End ---------------------------------- */}
          </div>
        </div>
        {/* ------------------------------ MAIN : END ------------------------------ */}
      </Layout>
    </>
  );
};
const ImageWrapper = styled.div`
  margin-bottom: unset !important;
`;
const HeadSeo = styled.h2`
  position: absolute;
  visibility: hidden;
  overflow: hidden;
`;
export async function getServerSideProps ({ req, res, params, query }) {
  const initProps = {
    statusCode: 200,
    errMessage: null,
    article: null,
    related: null,
    lastNews: null,
    solrRelated: [],
    lastArticleId: LAST_ARTICLE_ID || null,
    dataAffiliate: [],
    dataAdsNative: null,
    dataAdsHeader: null,
    ads: [],
    agent: null
  };

  const { expires, signature } = query;

  let preview = '';
  let previewParams = {};
  if (expires && signature) {
    preview = '/preview';
    previewParams = {
      expires,
      signature
    };
  }

  const url = req.url;
  let uri = `contents/${rewriteTxt(params.id)}`;
  if (url.startsWith('/advertorials')) {
    uri = `contents/${params.id}/${query.type}`;
  } else if (url.startsWith('/pr-news')) {
    uri = `contents/${params.id}/${query.type}`;
  } else if (url.startsWith('/blogs')) {
    uri = `contents/${params.id}/${query.type}`;
  }

  if (rewriteTxt(params.id) === 'latest-news') {
    return {
      redirect: {
        destination: `${APP_URL}/category/latest-news`,
        permanent: false
      }
    };
  }

  try {
    const resData = await GET_ARTICLES(
      `/api/v1.0/${uri}${preview}${convertObjPath({ ...previewParams })}`,
      false,
      true
    );
    const adsHeaders = await GET('/api/headerAds');
    if (!_.isEmpty(adsHeaders)) {
      initProps.dataAdsHeader = adsHeaders;
    }
    if (resData?.statusCode === 404 || _.isUndefined(resData)) {
      res.setHeader('location', '/');
      res.statusCode = 301;
      res.end();
    }
    if (!_.isEmpty(resData?.data)) {
      initProps.article = resData?.data;
      initProps.related = resData?.related;
      initProps.lastNews = resData?.last_news;
      initProps.solrRelated = resData?.solrRelated;
      initProps.ads = resData?.ads;
      initProps.dataAffiliate =
        params.id > initProps.lastArticleId ? resData?.affiliate : [];
      initProps.dataAdsNative =
        // {
        //   cat_id: 16,
        //   group_id: 111,
        //   name: 'mgid',
        //   header:
        //     "<script type='text/javascript' src='https://jsc.mgid.com/t/n/tnews.co.th.1486538.js' async ></script>",
        //   body: "<div id='M613908ScriptRootC1486538' />",
        //   mobile_header:
        //     "<script type='text/javascript' src='https://jsc.mgid.com/t/n/tnews.co.th.1486538.js' async ></script>",
        //   mobile_body: "<div id='M613908ScriptRootC1486538' />"
        // } || [];
        initProps.dataAdsNative = resData?.adsNative || [];
      initProps.agent = req.headers['user-agent'];
    }
  } catch (err) {
    console.log('==========> ARTICLE_DETAIL ERROR', err?.message);
    initProps.errMessage = err?.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  // const { data } = await useFetch('/api/adsNative');
  // if ('msg' in data) initProps.dataAdsNative = null;
  // initProps.dataAdsNative = data.data;

  return {
    props: initProps
  };
}

export default PageDetail;
