import Cookies from 'js-cookie';
import { CLIENT_GSERVICE_URL } from 'src/constants';
import { WEB_NAME } from 'src/constants/layout';

export const getCountviews = async (_id) => {
  if (!_id) return null;

  const _ga = await Cookies.get('_ga');
  const _gid = await Cookies.get('_gid');
  const params = {
    site: `${WEB_NAME}`,
    ga: _ga,
    gid: _gid,
    newsid: _id
  };
  const uri = `${CLIENT_GSERVICE_URL}/cpvs`;
  try {
    const res = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });
    const resData = await res.json();
    if (resData) return resData;
  } catch (err) {
    console.error(`==========> GET_COUNTVIEWS ERROR : ${uri}`, err);
  }
};
