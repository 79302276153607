import { SOCIAL_LIST } from 'src/constants/layout';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
import styled from 'styled-components';
const WidgetFollowUs = () => {
  const router = useRouter();
  return (
    <>
      <div className='mb-5'>
        <div className='section-title --azure'>
          <HeadingTag title={'ติดตามเรา'} type={KEY_HEADING_TAG.DETAIL} />
        </div>
          <FooterSocial className='footer-social'>
            {SOCIAL_LIST.map((item, index) => (
              <li key={index}>
                <a
                  href={item?.link}
                  title={item?.name}
                  target='_blank'
                  rel='noreferrer noopener nofollow'
                   // ! DATALAYER
                   onClick={() => {
                     sendDataLayerGlobal({
                       type: DATALAYER_TYPE.TRACK_POSITION,
                       router: router?.pathname,
                       section: 'ติดตามเรา',
                       position: 'ใต้:header',
                       data: {
                         block: '2',
                         title: item?.name,
                         heading: item?.name,
                         index: index + 1
                       }
                     });
                   }}
                >
                  {item?.icon}
                </a>
              </li>
            ))}
          </FooterSocial>
      </div>
    </>
  );
};
const FooterSocial = styled.ul`
  li .--X {
    padding: 9px;
  }
`;
export default WidgetFollowUs;
