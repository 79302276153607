import { BsTwitterX } from 'react-icons/bs';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterShareButton } from 'react-share';
import { APP_URL } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

const WidgetSocialShare = ({ articleLink, eventDataLayer }) => {
  const shareLink = `${APP_URL}${articleLink}`;
  const SOCIAL_SHARE = [
    {
      id: 1,
      name: 'facebook',
      icon: (
        <FacebookShareButton url={shareLink}>
          <FacebookIcon size={36} round={'true'} />
        </FacebookShareButton>
      )
    },
    {
      id: 2,
      name: 'X',
      icon: (
        <TwitterShareButton url={shareLink}>
          <div className='x-wrapper'>
            <BsTwitterX size={36} round={'true'} />
          </div>
        </TwitterShareButton>
      )
    },
    {
      id: 3,
      name: 'line',
      icon: (
        <LineShareButton url={shareLink}>
          <LineIcon size={36} round={'true'} />
        </LineShareButton>
      )
    },
    {
      id: 4,
      name: 'email',
      icon: (
        <EmailShareButton url={shareLink}>
          <EmailIcon size={36} round={'true'} />
        </EmailShareButton>
      )
    }
  ];
  return (
    <WidgetWrapper className='widget-social-share'>
      <ul>
        {SOCIAL_SHARE?.map((item, index) => (
          <li
            key={index}
            className={`--${item?.name ?? ""}`}
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  ...eventDataLayer?.data,
                  title: item?.name,
                  index: index + 1
                }
              })
            }
          >
            {item.icon}
          </li>
        ))}
      </ul>
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  .x-wrapper {
      width: 36px;
      height: 36px;
      background-color: #000;
      border-radius: 50%;
      svg {
        padding: 10px;
        color: #fff;
      }
    }
`;
export default WidgetSocialShare;
