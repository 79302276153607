import dynamic from 'next/dynamic';

import Layout from 'src/components/layouts';
// import { arrayEmpty } from 'src/utils/helper';

import { useFetch } from 'services/useFetch';
import { useEffect, useState } from 'react';
import CardV from 'src/components/cards/card-v';
import Carousels from 'src/components/carousels';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';

const HeadingPage = dynamic(import('src/components/heading/heading-page'));

const EmptyArticle = ({ type, data, lastNews }) => {
  const [dataLatestNews, setDataLatestNews] = useState(lastNews);

  useEffect(() => {
    if (!lastNews) {
      (async () => {
        try {
          const uri = '/api/v1.0/categories/latest-news';
          const { data } = await useFetch(uri);
          // console.log(data);
          if (!data) throw new TypeError(uri);
          setDataLatestNews(data.data);
        } catch (err) {
          console.error(`==========> EMPTY_ARTICLE ERROR : ${err}`);
        }
      })();
    }
  }, []);
  return (
    <Layout>
      <NextSeo
        noindex={true}
        nofollow={true}
        title='ไม่มีข่าวนี้หรือถูกลบไปแล้ว >>> Tnews'
        description='ไม่มีข่าวนี้หรือถูกลบไปแล้ว >>> Tnews' />
        <Head>
          {/* -------------------------------- ADS : PPN ------------------------------- */}
          {/* <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
          <script type='text/javascript' dangerouslySetInnerHTML={{
            __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
          }} /> */}
        </Head>
      <div className='empty-article' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <div className='container'>
          {/* ---------------------------- HEADING  ----------------------------------- */}
          {data && <HeadingPage type={type} data={data} /> }
          <img className='no-article' src={`${APP_IMG}/images/tnews-no-article.svg`} alt='no-article' width='300px' height='300px' />
          {/* ----------------------------- LATEST_NEWS  --------------------------------- */}
            {Array.isArray(dataLatestNews) &&
              <div className='mt-3'>
                <Carousels slideShow={4}>
                {dataLatestNews.map((item, index) =>
                <div className='pe-0 pe-md-4'>
                  <CardV
                    key={index}
                    data={item}
                    path={item.link}
                  />
                  </div>
                )}
              </Carousels>
            </div>
            }
        </div>
      </div>
    </Layout>
  );
};

export default EmptyArticle;
