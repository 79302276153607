import _ from 'lodash';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { BsTwitterX } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterShareButton } from 'react-share';
import { APP_IMG, APP_URL } from 'src/constants';
import { sendDataLayerGlobal, sendDataMeta } from 'src/utils/datalayer/send-datalayer';
import { useResponsive } from 'src/utils/device';
import styled from 'styled-components';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';
import Image from '../image';

const WidgetGallery = ({ id, data, eventDataLayer, articleLink, link = '', title = '', article }) => {
  if (_.isEmpty(data)) return null;
  const router = useRouter();
  const responsive = useResponsive();

  const shareLink = `${APP_URL}${link}`;
  const SOCIAL_SHARE = [
    {
      id: 1,
      name: 'facebook',
      icon: (
        <FacebookShareButton url={shareLink}>
          <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
      )
    },
    {
      id: 2,
      name: 'x',
      icon: (
        <TwitterShareButton url={shareLink}>
          <div className='x-sticky-wrapper'>
            <BsTwitterX />
          </div>
        </TwitterShareButton>

      )
    },
    {
      id: 3,
      name: 'line',
      icon: (
        <LineShareButton url={shareLink}>
          <LineIcon size={36} round={true} />
        </LineShareButton>
      )
    },
    {
      id: 4,
      name: 'email',
      icon: (
        <EmailShareButton url={shareLink}>
          <EmailIcon size={36} round={true} />
        </EmailShareButton>
      )
    }
  ];
  const elementRef = useRef(null);

  const handleOpen = (item) => {
    try {
      // const currentPath = window.location.pathname;
      sendDataMeta({
        id: `${item.id}`,
        category: `อัลบั้มภาพ`,
        author: article?.created_by,
        keyword: `${article?.tags}`,
        createdAt: `${article?.created_at}`
      });

      localStorage.setItem('article-data', JSON.stringify({ title: title, author: article?.created_by, link: articleLink, group: id }));
      localStorage.setItem('preview', JSON.stringify(item?.id));
      router.push(`${articleLink}/${link}/${item?.id}`);
    } catch (error) {
      console.log('Error: Please enable website cookies. ' + error);
    }
    // window.location.replace(`${currentPath}/${link}/${item.id}`);
  };

  const handleOpenAll = () => {
    try {
      sendDataMeta({
        id: 'ดูทั้งหมด',
        category: `อัลบั้มภาพ`,
        author: article?.created_by,
        keyword: `${article?.tags}`,
        createdAt: `${article?.created_at}`
      });
      // const currentPath = window.location.pathname;
      localStorage.setItem('article-data', JSON.stringify({ title: title, author: article?.created_by,  link: articleLink, group: id }));
      router.push(`${articleLink}/${link}`);
    } catch (error) {
      console.log('Error: Please enable website cookies. ' + error);
    }
    // window.location.replace(`${currentPath}/${link}/${item.id}`);
  };

  // const checkParameter = (url) => {
  //   return url.split('?') ? url.split('?')[0] : url;
  // };

  // const handleAfterOpen = () => {
  //   if (elementRef.current) {
  //     elementRef.current.style.display = 'flex';
  //   }
  // };
  // const handleBeforeClose = () => {
  //   if (elementRef.current) {
  //     elementRef.current.style.display = 'none';
  //   }
  // };
  // const currentPath = window.location.pathname;

  // console.log('articleLink :', articleLink);
  const customIndex = responsive.isMobile ? (data?.length < 3 ? data?.length - 1 : 2) : ( data?.length < 4 ?  data?.length - 1 : 3);

  return (
    <>

      <WidgetWrapper className='widget-gallery'>
        {/* <WidgetHeadingTab heading={`อัลบั้มภาพ ${data.length || '-'} ภาพ`}  > */}
        <div className='section-title'>
        <HeadingTag
            heading={`อัลบั้มภาพ ${data?.length || '-'} ภาพ`}
            type={KEY_HEADING_TAG.SECTION_SECTION}
            path={null}
            />
    </div>
        {/* <WidgetHeader
          headingText={`อัลบั้มภาพ ${data.length || '-'} ภาพ`}
          imgSrc={`${APP_IMG}/images/3.0/header-blue.webp`}
          eventDataLayer={null} /> */}

        {!_.isEmpty(data) && (
          <>
            <div className='gallery-content-listnews' >

              {customIndex > 0 && data?.slice(0, customIndex).map((item, index) => (
                <div
                  data-src={`${APP_IMG}${item?.file}`}
                  // data-poster={`${APP_IMG}/default.webp` }
                  data-poster={item?.type === 'video' ? `${APP_IMG}/default.webp` : ''}
                  data-download-url={false}
                  key={index}
                  className={'gallery-content-item'}
                  onClick={() => handleOpen(item)}
                >

                  <a href={`${articleLink}${link}/${item?.id}`} className='item-gallery'>
                    {customIndex && <div className='card-image'
                      // ! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          ...eventDataLayer,
                          data: {
                            ...eventDataLayer.data,
                            title: item.id,
                            index: index + 1
                          }
                        })
                      }>
                      <Image
                        external={true}
                        image={item?.type === 'video' ? `${APP_IMG}/default.webp` : `${APP_IMG}${item?.file}`}
                        alt={data?.id || 'image-gallery'}
                        className='img-responsive hover-shadow'
                      />
                    </div>}

                  </a>
                </div>
              ))}
              <a href={`${articleLink}${link}`} className='item-gallery'>
                <div
                  data-src={`${APP_IMG}${data[customIndex]?.file}`}
                  // data-poster={`${APP_IMG}/default.webp` }
                  data-poster={data[customIndex]?.type === 'video' ? `${APP_IMG}/default.webp` : ''}
                  data-download-url={false}
                  className={'gallery-content-item --last'}
                  onClick={() => handleOpenAll()}

                >
                  <div className='item-gallery'
                    // ! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          ...eventDataLayer.data,
                          title: 'ดูทั้งหมด',
                          index: customIndex
                        }
                      })
                    }>
                    <div className='card-image'>
                      <Image
                        external={true}
                        image={data[customIndex]?.type === 'video' ? `${APP_IMG}/default.webp` : `${APP_IMG}${data[customIndex]?.file ?? '/default.webp'}`}
                        alt={data[customIndex]?.id || 'image-gallery'}
                        className='img-responsive hover-shadow'
                      />
                    </div>
                    <div className='views-all' >
                      <FaEye size={20} /> <span> ดูทั้งหมด </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </>
        )}
        {/* </WidgetHeadingTab > */}
      </WidgetWrapper >
      <SideBarWrapper ref={elementRef} className='gallery-side-bar'>
        <div className='section-title'>
          {/* <WidgetHeading heading={`ทั้งหมด ${data.length} ภาพ`} /> */}
          <HeadingTag
            heading={`ทั้งหมด ${data?.length} ภาพ`}
            type={KEY_HEADING_TAG.SECTION_SECTION}
            path={null}
            />
        </div>

        <div className='share grid'>
          {SOCIAL_SHARE.map((item, index) => (
            <div className='share item' key={index}>
              <div
                key={index}
                className={`icon-${item.name}`}
                // ! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    ...eventDataLayer,
                    data: {
                      ...eventDataLayer.data,
                      title: item.name,
                      index: index + 1
                    }
                  })
                }
              >
                {item?.icon}
              </div>
            </div>
          ))}
        </div>
        <p className='desc-text'>{title}</p>

      </SideBarWrapper>
    </>
  );
};

export default WidgetGallery;

const SideBarWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 20%;
  height: 100vh;
  background-color: #fff;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 1s ease-in-out;
  z-index: 20;
  @media (min-width: 1600px) {
    width: 15%;
  }
  @media (max-width: 767px) {
    display: none !important;
  }
  .side-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 0;
    .left-header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        color: #000;
        font-size: 18px;
      }
    }
    .right-header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      border-left: 1px solid #eee;

    }
    svg {
      color: #000;
    }
  }
  .desc-text {
    color: #000;
    font-size: 18px;
    padding: 10px;
  }
  .share.grid {
    display: flex;
    gap: 10px;
    width: 100%;
    align-self: flex-start;
    padding: 10px 10px;
    /* border-top: 1px solid #eee; */
    border-bottom: 1px solid #eee;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 5px;
        background-color: #ffffff;
        border-radius: 10px;
      }
  
      &::-webkit-scrollbar-track {
        background-color: #ffffff21;
        border-radius: 10px;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--primary-color)
      }
  }
  .share.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    gap: 4px;
    .--X button {
      display: flex;
      align-items: start;
      height: 43px;
    }

  }
  .x-sticky-wrapper {
    width: 36px;
    height: 36px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      color: #fff;
      font-size: 18px;
    }
  }
   
`;
const WidgetWrapper = styled.div`
  /* default */
  .gallery-content-list {
    position: relative;
    column-count: 2;
    margin-bottom: 20px;
    .item-gallery {
      position: relative;
      img {
        margin: 0 !important;
      }
      .viewmore {
        /* border-radius: 8px !important; */
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: auto;
        background-color: rgba(0,0,0,0.5);
      }
    }
    .more-gallery {
      background: rgba(10, 10, 10, 0.5);

      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
      /* border-radius: 8px; */
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 26px;
        font-weight: 300;
        white-space: nowrap;
      }
    }
    .gallery-content-item {
      padding: 5px 0;
      position: relative;
      cursor: pointer;
    
      iframe {
        max-width: 100%;
        /* border-radius: 8px; */
      }
      &.--d-none {
        display: none;
      }
      .card-image {
        position: relative;
        padding-top: 130%;
        background-image: url(${APP_IMG}/default.webp);
        /* border-radius: 15px; */
        background-size: cover;
        background-repeat: repeat;
        .resolution-image {
          box-shadow: 0px 0px 2px 0px #b7b7b7;
          /* border-radius: 15px; */
          position: absolute;
          width: 100% !important;
         
          height: 100% !important;
          top: 0;
          left: 0;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
          }
        }
      }
    }
  }

  .gallery-content-listnews {
    position: relative;
    background-color: #fff;
    column-count: 4;
    /* border-radius: 15px; */
    padding: 15px 0px;
    gap: 10px;
    margin: 0 0 20px 0;
    @media (max-width: 690px) {
      column-count: 3;
      /* gap: 10px; */

    }

    .item-gallery {
      position: relative;
      img {
        /* margin-bottom: -5px; */
        /* border-radius: 8px !important; */
      }
      .viewmore {
        /* border-radius: 8px !important; */
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: auto;
        background-color: rgba(0,0,0,0.5);
      }
    }
    .gallery-content-item {
      /* padding: 5px 0; */
      position: relative;
      cursor: pointer;
      &.--last {
        &:last-child {
          position: relative;
          .item-gallery {

            &:before {
              content:'';
              position: absolute;
              top: 0;
              color: #fff;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              display: flex;
              /* border-radius: 8px; */
              justify-content: center;
              align-items: center;
              background: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
      iframe {
        max-width: 100%;
        /* border-radius: 8px; */
      }
    }
  }
  
  .card-image {
    .resolution-image {
      /* border-radius: 15px; */
      width: 100%;
      height: 100%;
      /* padding-top: 100% !important; */
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
    }
  }
  .views-all {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* bottom: 0; */
    display: block;
    z-index: 1;
    
    span, svg { 
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff ;
        width: 100%;
    }
    span { 
        margin-top: 8px;
        font-size: 16px;
    }
  }

`;
