export function setContents () {
  // const contents = document.getElementById('contents');
  const contents = document.getElementById('paragraph');

  const _p = contents?.querySelectorAll('p');
  const _iframe = contents?.querySelectorAll('p iframe');
  const _image = contents?.querySelectorAll('picture img');
  // const _source = contents?.querySelectorAll('picture source');
  const _picture = contents?.querySelectorAll('p picture');
  const _table = contents?.getElementsByTagName('table');

  if (_p) {
    for (let i = 0; i < _p.length; i++) {
      const _style = _p[i]?.getAttribute('style');
      const _n = _style ? `${_style || ''} line-height: 1.85 !important;` : 'line-height: 1.85 !important';
      if (_p[i]) {
        _p[i].setAttribute('style', `${_n}`);
      }
    }
  }

  if (_image) {
    for (let i = 0; i < _image.length; i++) {
      // if (_source[i]) {
      //   const _src = _source[i].getAttribute('srcset');
      //   // _source[i].setAttribute('srcset', _src + '?x-image-process=style/LG-webp');
      // }
      if (_image[i]) {
        const _height = _image[i].getAttribute('height');
        const _width = _image[i].getAttribute('width');
        const _style = _image[i].getAttribute('style');
        // const _src = _image[i].getAttribute('src');
        // const _class = _image[i].getAttribute('class');
        // const _showcaption = _class?.search('showcaption-true') !== -1;
        // console.warn('image', { _class, _showcaption });
        // _image[i].setAttribute('src', _src + '?x-image-process=style/LG-webp');
        const _newStyle = `
            display: block !important;
            width: 100% !important;
            height: 100% !important;
            max-height: ${_height ? `${_height}px` : '100%'} !important;
            max-width: ${_width ? `${_width}px` : '100%'} !important;
            margin-bottom: 10px !important;
          `;
        const _n = _style ? `${_style || ''}${_newStyle}` : _newStyle;
        _image[i].setAttribute('style', _n);

        if (_picture[i]) {
          const _img = _picture[i].getElementsByTagName('img')[0];

          if (_img) {
            const _caption = document.createElement('span');
            const _class = _img.getAttribute('class');
            const _showcaption = _class.search('showcaption-true') !== -1;
            if (_caption) {
              _caption.className = 'image-caption';
              _caption.style = `
                  display: block !important;
                  max-width: ${_width && _width > 0 ? `${_width}px` : '100%'} !important;
                  margin: auto !important;
                  margin-top: -5px !important;
                  margin: -10px auto 10px auto !important;`;
              _caption.innerHTML = _image[i].alt || null;
              if (_showcaption) {
                _picture[i].insertAdjacentElement('beforeend', _caption);
              }
            }
          }
        }
      }
    }
  }

  if (_iframe) {
    for (let i = 0; i < _iframe.length; i++) {
      if (_iframe[i]) {
        const _width = _iframe[i].getAttribute('width');
        const _height = _iframe[i].getAttribute('height');
        _iframe[i].setAttribute('style', `
              display: block !important;
              position: relative !important;
              margin: 15px auto !important;
              width: 100% !important;
              height: 100% !important;
              min-height: ${_height ? `${_height}px` : '480px'} !important;
              max-width: ${_width ? `${_width}px` : '100%'} !important;
              max-height: ${_height ? `${_height}px` : '100%'} !important;
            `);
      }
    }
  }

  // scroll table
  if (_table) {
    for (let i = 0; i < _table.length; i++) {
      if (_table[i]) {
        const _div = document.createElement('div');
        _div.setAttribute('style', 'overflow-x:auto;');
        const _TableScroll = _table[i].insertAdjacentElement('beforebegin', _div);
        // retrun
        _table[i].setAttribute('style', 'width:540px; margin: auto;');
        _TableScroll.append(_table[i]);
      }
    }
  }
};
